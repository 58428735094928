<template>
  <div class="store_btn_container" :class="[mdHidden && 'md:hidden']">
    <a class="store_btn inline-flex"
       @click.prevent="handleLinkEvent('IOS')"
       href="https://apps.apple.com/ng/app/apex-network/id1549187308">
      <span class="sr-only">Download from apple store</span>
      <IconAppleLogo/>
    </a>
    <a class="inline-flex store_btn"
       @click.prevent="handleLinkEvent('Android')"
       href="https://play.google.com/store/apps/details?id=ng.apexnetwork.apex_network">
      <span class="sr-only">Download from google play store</span>
      <IconGoogleStoreLogo/>
    </a>
  </div>
</template>
<script setup lang="ts">
import {RudderStack} from "~/utils/rudderStack";

const handleLinkEvent = (store: "Android" | "IOS") => {
  RudderStack.appInstall(store);

  setTimeout(() => {
    if(store === "IOS") {
      window.open('https://apps.apple.com/ng/app/apex-network/id1549187308', '_blank');
    }
    else if(store === "Android") {
      window.open('https://play.google.com/store/apps/details?id=ng.apexnetwork.apex_network', '_blank');
    }
    else {
      return null;
    }
  }, 1000);

}

defineProps({
  mdHidden:{
    type: Boolean,
    default: true
  }
})
</script>